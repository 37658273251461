/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {

                /**
                 * Returns a specified cookie
                 * @param cname name of the cookie
                 * @returns {string} the cookie as a string
                 */
                function getCookie(cname) {
                    var name = cname + "=";
                    var ca = document.cookie.split(';');
                    for(var i=0; i<ca.length; i++) {
                        var c = ca[i];
                        while (c.charAt(0)===' ') {
                            c = c.substring(1);
                        }
                        if (c.indexOf(name) === 0) {
                            return c.substring(name.length,c.length);
                        }
                    }
                    return null;
                }

                /**
                 * Sets the username & logout link if logged in and/or the amount of items
                 * in the cart if any
                 * @param cookieStr the blog cookie as a string
                 */
                function setUserDataToBlogTemplate(cookieStr) {
                    if (!cookieStr) {
                        return;
                    }

                    var cookie = JSON.parse(unescape(cookieStr));

                    var cart = cookie.cart;
                    var customer = (cookie.customer) ? cookie.customer.replace(/\+/g, " ") : false;
                    var url = "http://marittir.ch/"; // Trailing Slash nicht vergessen!

                    // Setzt den Logout-Link und den Account-Link wenn der User eingeloggt ist
                    if(customer) {
                        var linksstring = '<a href="'+url+'customer/account/" class="skip-link skip-account"><span class="label">Benutzerkonto</span></a><span class="seperator">|</span><a href="'+url+'customer/account/logout/" class="skip-link skip-logout"><span class="label">Logout</span></a><span class="seperator">|</span><a href="'+url+'checkout/cart/" class="skip-link skip-cart"><span class="label">Warenkorb</span></a><a href="'+url+'checkout/cart/" class="count-cart-wrapper"><span class="cart no-count"></span></a>';
                        $('div.header-minicart div.hidden-xs').html(linksstring);

                        // Für die Mobile Navigation
                        var linksstringmobile = '<div class="icon-link-wrapper"><a href="'+url+'checkout/cart/" class="count-cart-wrapper"><span class="cart no-count"></span></a><a href="'+url+'checkout/cart/" class="skip-link skip-cart"><span class="label">Warenkorb</span></a></div><div class="icon-link-wrapper"><span class="icon-account"></span><a class="account-link" href="'+url+'customer/account/" title="Benutzerkonto"><div class="label">Benutzerkonto</div></a></div><div class="icon-link-wrapper"><span class="icon-logout"></span><a id="logout-link" href="'+url+'account/logout/"><span class="label">Logout</span></a></div>';
                        $('div.header-minicart div.visible-xs').html(linksstringmobile);

                    }

                    // Setzt den Counter des Warenkorbs
                    if(cart) {
                        $('a.count-cart-wrapper').html("<span class='cart'></span><span class='count'>"+parseInt(cart)+"</span>");
                    }
                }

                setUserDataToBlogTemplate(getCookie('blog'));
            }
        },
        finalize: function () {

        },
        // Home page
        'home': {
            init: function () {
                // fired on HOmepage
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
